import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useForm } from 'react-hook-form'
import useFormUrl from '../useFormUrl'
import {Checkbox, Select, DatePicker, InputNumber, Drawer } from 'antd';
import moment from 'moment';
import {Row, Col, Container} from '../../ui/wrapper'
import Form, { FormGroup, Input, Error } from '../../ui/form'
import Button from '../../ui/button'
import airports from 'airport-data';
import Plane from "../../../assets/svg/Plane";
import Hotel from "../../../assets/svg/Hotel";
import FlyingPlane from "../../../assets/svg/FlyingPlane";
import {formatPrice, formatNumber} from '../../../utils/utilFunctions';
import {CheckoutDrawer} from '../../ui/form/form.style'
import CheckoutForm from "../../../components/checkoutForm";
import { loadStripe } from "@stripe/stripe-js";
import Alert from '../../../components/ui/alert';
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import {FaExclamationCircle} from "react-icons/fa";


const { Option } = Select;



const stripePromise = loadStripe(process.env.STRIPE_KEY);


const AppointmentForm = ({trip, hotels}) => {
  let flight_url;
  const [submitActive, setSubmitActive] = useState({
    formActive: true
  });

  const [itineraryCollection, setItineraryCollection] = useState({
    quantity: 1,
    oneWay: false,
    airport: null,
    date: null

  });

  const [airport, setAirport] = useState();
  const [date, setDate] = useState();
  const [oneWay, setOneWay] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [getRates, setGetRates] = useState(false);
  const [hotelRate, setHotelRate] = useState(false);
  const [flightRate, setFlightRate] = useState(false);
  const [hotelPrice, setHotelPrice] = useState();
  const [flightPrice, setFlightPrice] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorType, setErrorType] = useState('');



  const formUrl = useFormUrl();
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur"
  })

  const us_airports = airports.filter(function (el) {
    return el.country == 'United States'
  });


  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });


  const onError = (err) => {

  }


  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const onSubmit = (data, e) => {
    const form = e.target;

    const info = {
      airport,
      date,
      oneWay,
      quantity
    }


    runHotelPrice();
  }

  const runHotelPrice = () => {
    const date_end = moment(date).add(trip.Period, "days").format("YYYY-MM-DD");
    setGetRates(true);
    setHotelRate(true);
    hotels.map(h => {
      const hotel_url = h.node.data.BookingUrl;
      axios.get(
          "https://api.triploaf.com/gethotelprice?link="+hotel_url+"&check_in="+date+"&check_out="+date_end,{
            headers: {
              'x-api-key':process.env.TRIPLOAF_API_KEY
            }
          }
        )
        .then((response) => {
          setHotelPrice(response.data.Total);
          setHotelRate(false);
          runFlightPrice()
          setFlightRate(true);
        })
        .catch(function (error) {
          setIsError(true);
          setErrorType('hotel')
        });
    })
  }


  const runFlightPrice = () =>{
    const flight_start = moment(date).format("DD/MM/YYYY");
    const flight_end = moment(date).add(trip.Period, "days").format("DD/MM/YYYY");
    if (oneWay == true) {
      flight_url = "https://api.triploaf.com/getflightprice?source="+airport.iata+"&dest="+trip.DestinationAirportCode+"&date="+flight_start;
    } else {
      flight_url = "https://api.triploaf.com/getflightprice?source="+airport.iata+"&dest="+trip.DestinationAirportCode+"&date="+flight_start+"&return_date="+flight_end;
    }

    axios.get(
          flight_url,{
            headers: {
              'x-api-key':process.env.TRIPLOAF_API_KEY
            }
          }
        )
        .then((response) => {
          setGetRates(false);
          setFlightPrice(response.data.Data[0].Price * quantity);
          setFlightRate(false);
        })
        .catch(function (error) {
             setIsError(true)
             setErrorType('flight')
        });
  }


  useEffect(() => {
    if(airport && date){
      setSubmitActive({
        formActive: false,
      });
    }
    if(flightPrice && hotelPrice){
      setTotalPrice((flightPrice + hotelPrice) + ((flightPrice + hotelPrice) * 0.03 + 0.29));
    }
  }, [airport, date, flightPrice, hotelPrice]);



  const onAirportChange = value => {
    setAirport(us_airports[value]);
  }

  const datePickerChange = e => {
    setDate(moment(e).format("YYYY-MM-DD"));
  }

  const oneWayChange = e =>{
    setOneWay(e.target.checked ? e.target.checked : false);
  }

  const onQuantityChange = value => {
    setQuantity(value);
  }

  const runCheckout = () => {
    setDrawerVisible(true);
  }

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const closeErrorForm = () => {
      setIsError(false);
      setGetRates(false);
      setErrorType('')
      setFlightRate(false);
  }

  return (
    isError
      ?
      <Container>
        <Alert variant="danger" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div>
            <FaExclamationCircle className="icon" />  {errorType == 'hotel' && 'Sorry, this hotel isn\'t available for these dates' } 

            {errorType == 'flight' && 'Sorry, no flights available for these dates and airport selection' }
          </div>
          <Button skin="danger" ml="50px" onClick={()=>closeErrorForm()}>Close</Button>
        </Alert>
      </Container>
      : <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup mb="0px" ml="auto" mr="auto">
            <div className="trip-form-container">
              <Row>
                <Col lg={12}>
                  <h5>
                    Fill out information below to get up-to-date pricing for itinerary
                  </h5>
                </Col>
                <Col lg={4} className="pl-0 mb-4">
                  <Col lg={12}>
                    <label>Tell us where you're flying from.</label>
                  </Col>
                  <Col lg={12} className="pr-0">
                    <Select
                      showSearch
                      autoComplete="off"
                      className="airport-select"
                      placeholder="Search for airport"
                      optionFilterProp="children"
                      onChange={onAirportChange}
                    >
                      {us_airports.map((airport, index) => <Option
                        value={index}> {airport.iata} - {airport.city} - {airport.name} </Option>)}
                    </Select>
                  </Col>
                </Col>
                <Col lg={3} className="mb-4">
                  <Row>
                    <Col lg={12}>
                      <label>Select depart date.</label>
                    </Col>
                    <Col lg={12}>
                      <DatePicker onChange={datePickerChange} format='MM/DD/YYYY'/>
                      <Checkbox className="one-way" style={{marginLeft: '25px'}} onChange={oneWayChange}>One way?</Checkbox>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2} className="mb-4">
                  <Row>
                    <Col lg={12}>
                      <label>How many people?</label>
                    </Col>
                    <Col lg={12}>
                      <InputNumber className="itinerary-quantity" size="large" min={1} max={20} defaultValue={1}
                                   onChange={onQuantityChange}/>
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} mt="20px">
                  {flightPrice && hotelPrice ? (
                      <span className="button"
                            onClick={() => runCheckout()}
                            pl="54px"
                            pr="54px"
                            hover="2">${formatNumber(totalPrice)} Purchase Now</span>
                    )
                    : (
                      <Button
                        type="submit"
                        pl="54px"
                        pr="54px"
                        disabled={submitActive.formActive}
                        hover="2">Get Price and Plan</Button>
                    )
                  }
                </Col>
              </Row>
              <Row>
                {serverState.status && (
                  <p className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>
                    {serverState.status.msg}
                  </p>
                )}
                <Error>{errors.appointment_inquiry && errors.appointment_inquiry.message}</Error>
              </Row>
            </div>

          </FormGroup>
          {getRates == true && (
            <div className="loading-modal-container">
              <div className="loading-modal">
                {hotelRate == true && (
                  <div>
                    <Hotel color="#326CFC"/>
                    <p>
                      Getting average hotel rates for trip
                    </p>
                  </div>
                )}
                {flightRate == true && (
                  <div>
                    <Plane color="#326CFC"/>
                    <p>
                      Getting average flight rates for trip
                    </p>
                  </div>
                )}
                <FlyingPlane/>
              </div>
            </div>
          )}
        </Form>

        <Drawer
          title={
            <h5>
              ${formatNumber(totalPrice)} - {trip.Title}
            </h5>
          }
          width="50%"
          onClose={closeDrawer}
          visible={drawerVisible}
          className="checkout-drawer"
          bodyStyle={{paddingBottom: 80}}
        >

          <Elements
            stripe={stripePromise}
            price={Math.round(totalPrice)}
          >
            <CheckoutDrawer>
              <CheckoutForm
                price={Math.round(
                  hotelPrice +
                  flightPrice +
                  (hotelPrice + flightPrice) * 0.03 +
                  0.29
                )}
              />
            </CheckoutDrawer>
          </Elements>

        </Drawer>
      </div>
  )
}


export default AppointmentForm;
