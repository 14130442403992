import React from 'react';
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import { Form, Input, InputNumber, Button, Steps, Checkbox, Row, Col, message} from 'antd';
import Airtable from 'airtable';
import CardSection from './CardSection';
import uniqid from 'uniqid';
import PurchaseLoading from '../../assets/svg/PurchaseLoading';
import FlyingPlane from '../../assets/svg/FlyingPlane';
import SuccessIcon from '../../assets/svg/SuccessIcon';

import {
  InfoCircleOutlined,
} from '@ant-design/icons';

const { Step } = Steps;

var base = new Airtable({apiKey: process.env.AIRTABLE_API_KEY}).base('app8d8K3oEWsHL8qH');


const steps = [
  {
    title: 'Your Info',
  },
  {
    title: 'Payment',
  },
  {
    title: 'Confirmation',
  },
];


const layout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 24
  },
};
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not validate email!',
    number: '${label} is not a validate number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

class CheckoutForm extends React.Component {


  constructor(props) {
      super(props);
      this.state = {
        name : '',
        address : '',
        current: 0,
        customer : {},
        processingOrder : false
      };
  }

  componentDidMount(){
    console.log('this is props', this.props);
  }


  createUser = values => {
    let {price} = this.props

    let customer = values

    customer.user.Paid = false;
    customer.user.Price = price;
    customer.user.id = uniqid();



    this.setState({customer})


    // base('Customers').find('recKjP1SawTAfktHP', function(err, record) {
    // if (err) { console.error(err); return; }
    // console.log('Retrieved', record);
    // });




    base('Customers').create([
              {
                "fields": customer.user
              }
            ], (err, records) => {
              if (err) {
                console.error(err);
                return;
              }
              records.forEach(record => {
                this.setState({
                  airtableId : record.getId(),
                  current : 1
                }, ()=>{
                  console.log('this is state', this.state);
                })
                console.log('this is the id bitch', record.getId());
              });
            });
  }

  updateUser = values => {
    const {airtableId} = this.state;
    let {customer} = this.state;
    customer = values;

    this.setState({customer});

    base('Customers').update([
      {
        "id": airtableId,
        "fields": values.user
      }
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function(record) {
        console.log('this is the record', record.get('Name'));
      });
    });

  }


  handleSubmit = async (event) => {
    const {name, address, airtableId} = this.state;
    let {price} = this.props
    let {customer} = this.state;

    console.log('this is price', price);
    price = price*100
    console.log('this is price', price);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const {stripe, elements} = this.props

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }



    fetch('https://fythibxi51.execute-api.us-east-1.amazonaws.com/stripe/auth?amount='+price+'&currency=usd').then(function(response) {
      return response.json();
    }).then(responseJson => {
      var clientSecret = responseJson.client_secret;
      console.log('this is the clientSecret', responseJson);
      // Call stripe.confirmCardPayment() with the client secret.
      const result = stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: customer.user.FirstName + ' ' +customer.user.LastName,
          },
        }
      });

                                          this.setState({processingOrder : true})

      result.then(r => {
        console.log('sdsdsds1', r);
        if (r.error) {

          setTimeout(()=>{
              this.setState({processingOrder : false})
          }, 1000)

                    console.log('sdsdsds3', r);

                    this.setState({
                      cardError : r.error.message
                    })

        // Show error to your customer (e.g., insufficient funds)
          console.log(r.error.message);
        } else {

          // The payment has been processed!
          if (r.paymentIntent.status === 'succeeded') {

            base('Customers').update([
              {
                "id": airtableId,
                "fields": {
                  Pid: r.paymentIntent.id,
                  Paid: true
                }
              }
            ], function(err, records) {
              if (err) {
                console.error(err);
                return;
              }
              records.forEach(function(record) {
                console.log('this is the record', record.get('Name'));
              });
            });

            this.setState({
              pidSet : true,
              current: 2
            })



            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
          }
        }
      })
    });

  };

      next = () => {
        const current = this.state.current + 1;
        this.setState({ current });
      }

      prev = () => {
        const current = this.state.current - 1;
        this.setState({ current });
      }

  inputOnchange = (event, type) => {
    this.setState({
      [type]: event.target.value
    }, ()=>{
      console.log('this is state', this.state);
    });
  }


  render() {
    const {current, airtableId, pidSet, customer, processingOrder, cardError} = this.state;
    return (
        <div>
         <div>
        <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">
        {current == 0 &&
          <div className="step-one">

            <p style={{marginTop:'20px'}}>Please provide us with the most accurate information, we do all the annoying booking for you and we'll be using this info to book your trip </p>
            <Form {...layout} name="nest-messages" onFinish={airtableId ? this.updateUser : this.createUser} validateMessages={validateMessages} initialValues={customer}>
              <Row>
                <Col span={8}>
                  <Form.Item name={['user', 'FirstName']} label="First Name" rules={[{  required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item name={['user', 'MiddleName']} label="Middle Name">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8} offset={1}>
                  <Form.Item name={['user', 'LastName']} label="LastName" rules={[{  required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <Form.Item name={['user', 'Email']} label="Email" rules={[{ type: 'email', required : true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={11} offset={1}>
                  <Form.Item
                    name={['user', 'Phone']}
                    label="Phone Number"
                    rules={[{ required: true, message: 'Please input your phone number!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name={['user', 'StreetAddress']} label="Street Address" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={5}>
                  <Form.Item name={['user', 'State']} label="State" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name={['user', 'City']} label="City" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name={['user', 'Zip']} label="Zip" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={5}>
                  <Form.Item name={['user', 'DOB']} label="Date of Birth" rules={[{ required: true}]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name={['user', 'Passport']} label="Passport" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={5} offset={2}>
                  <Form.Item name={['user', 'BagCheck']} label="Bag Check" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item wrapperCol={{ ...layout.wrapperCol}} >
                    <Button type="primary" htmlType="submit" style={{height:'60px', width:'100%', marginTop:'20px', borderRadius:'4px', fontWeight:'bold'}}>
                      {airtableId ? 'Update' : 'Submit' }
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

          </div>
        }
        {current == 1 &&
          <div>
          {processingOrder &&
              <Row justify="center">
                <Col span={24} style={{textAlign:'center'}}>
                  <PurchaseLoading width='50%'/>
                </Col>
                <Col span={24} style={{ marginTop:'-50px'}}>
                  <FlyingPlane/>
                </Col>
                <Col span={24} style={{textAlign:'center'}}>
                  <h3 style={{color:'#242943'}}><InfoCircleOutlined /> Your Order Is Being Processed</h3>
                </Col>
              </Row>
          }
            <form onSubmit={this.handleSubmit} className="purchase-form" style={{opacity:processingOrder ? '0' : '1'}}>
              <CardSection />
              {cardError && <span style={{width:'100%', float:'left', color:'#f00', marginBottom:'20px'}} className="error">*{cardError}</span> }
              <button disabled={!this.props.stripe}>Confirm order</button>
            </form>

          </div>
        }
        {current == 2 &&
          <div>
            <Row justify="center">
              <Col span={24} style={{textAlign:'center', marginTop:'40px'}}>
                <SuccessIcon width='30%'/>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{textAlign:'center'}}>
                <h2 style={{marginTop:'20px'}}>Confirmation Number : <span style={{borderBottom:'1px dotted #5bb543'}}> {customer.user ? customer.user.id : 'X09039l'}</span></h2>
                <h3 style={{width:'70%', textAlign:'left', padding:'40px', background:'#dee3fa', margin:'0 auto', fontSize:'16px'}}><InfoCircleOutlined /> Thank you for your order! We are excited to have the opportunity to plan a trip for you! Please make sure you save your confirmation. You will be able to access your itinerary anytime by visiting your Triploaf itinerary page. We will be contacting you by email shortly. If you have any questions at anytime please don't hesitate to reach out to utilizing our chat widget.</h3>
              </Col>
            </Row>
          </div>
        }
        </div>
        {airtableId && !pidSet &&
        <div className="steps-action">
          {current < steps.length - 1 && (
            <div>
            {current == 0 && airtableId && <Button className="button-next" type="primary" onClick={() => this.next()}>Next</Button>}
            </div>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => message.success('Processing complete!')}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button style={{fontWeight:'bold'}} onClick={() => this.prev()}>
              Previous
            </Button>
          )}
        </div>
        }
        </div>


      </div>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm price={props.price} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}

// <Input placeholder="Name" onChange={()=>this.inputOnchange(event, 'name')} value={name} />
//             <Input placeholder="Address" onChange={()=>this.inputOnchange(event, 'address')} value={address} />
//             <Button onClick={this.createUser}>sdsdsd</Button>
