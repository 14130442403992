import React from "react";

function Plane({color, width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "300"}
      height={height ? height : "300"}
      x="0"
      y="0"
      viewBox="0 0 95 95"
      fill={color ? color : "#12B077"}
    >
      <path d="M9.465 35.557c-1.787-.029-6.605 2.736-6.605 2.736s3.577 4.946 7.99 9.5l13.374-5.541s-10.707-6.627-14.759-6.695z"></path>
      <path d="M82.169 20.416l-19.717 8.167-26.488-9.22-9.565 3.963 17.763 12.833-18.248 7.559-13.375 5.54c3.926 4.048 8.512 7.785 11.837 7.46 6.227-.611 16.394-3.861 26.776-7.734l-4.167 26.653 9.109-3.773L70.287 41.35c9.984-4.198 17.401-7.609 17.401-7.609a7.211 7.211 0 00-5.519-13.325z"></path>
    </svg>
  );
}

export default Plane;
