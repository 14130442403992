import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../../components/seo";
import Layout from "../../containers/layout/layout";
import Header from "../../containers/layout/header/header-one";
import Footer from "../../containers/layout/footer/footer-one";
import { Container, Box, Row, Col } from "../../components/ui/wrapper";
import Heading from "../../components/ui/heading";
import Text from "../../components/ui/text";
import Icon from "../../components/ui/icon";
import Image from "../../components/image";
import VideoButton from "../../components/ui/video-button";
import Attraction from '../../containers/elements/box-large-image/section-three'
import { MdTrendingFlat } from "react-icons/md";
import Tabs, { TabHeader, NavItem, TabContent, TabPane } from '../../components/ui/tabs/layout-one'
import Timeline from '../../components/ui/timeline'
import TripForm from '../../components/forms/trip-form'
import FeaturesArea from '../../containers/index-services/features-area'



import ModalVideo from "../../components/ui/modal-video";
import Social, { SocialLink } from "../../components/ui/social";
import TestimonialSection from "../../containers/global/testimonial-area/section-one";
import CTA from "../../containers/global/cta-area/section-one";
import PostNav from "../../components/post-nav/layout-one";
import {
  SectionWrap,
  ListGroupWrap,
} from "../../containers/elements/lists/section-one/section.style";

import {List, ListItem, ListLink} from '../../containers/blog/post-list/post-list.style'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import AccordionWrap from "../../components/ui/accordion";
import {
  BannerArea,
  BannerTextWrap,
  OverviewArea,
  ClientBox,
  MetaBox,
  ProblemArea,
  ProblemBox,
  ProblemTextBox,
  ResultArea,
  FaqArea,
  VideoBoxWrap,
  VideoBtnWrap,
  NavigationArea,
  Navigtion,
} from "./trip-template.style";





const TripsTemplate = ({
  data,
  pageContext: { next, previous },
  location,
  ...restProps
}) => {

  const trip = data.trip.edges[0].node.data;
  const plan = data.plans.edges;

  const {DestinationAirportCode, Period, Photo, Slug, Title, TripBlurb, TripCTA, TripCity, TripPrice} = trip;

  let bannerImg, final_plan;

  if (Photo[0].url) {
    bannerImg = Photo[0].url;
  }




  const comparatorComposer = function(compareBy, nextComparator) { 
    const comparator = function(a, b) { 
        return ((Number(a.node.data[compareBy]) < Number(b.node.data[compareBy])) ? -1 : ((Number(a.node.data[compareBy]) > Number(b.node.data[compareBy])) ? 1 : (nextComparator ? nextComparator(a, b) : 0))); 
    }; 
    return comparator; 
  }; 

        //run each of these pairs of lines in the Chrome console to see the output 
  const compareByDayOrder = comparatorComposer("DayOrder"); 
  plan.sort(compareByDayOrder); 

  const compareByDayAndDayOrder = comparatorComposer("Day", compareByDayOrder); 
  const final_sort = plan.sort(compareByDayAndDayOrder); 


  let obj = {}
  final_sort.map((plan, index) => {
    if(!obj['day_'+plan.node.data.Day]){
      obj['day_'+plan.node.data.Day] = []
    }
    obj['day_'+plan.node.data.Day].push(plan);
  })


  final_plan = obj;


  const hotels = plan.filter(function (el) {
      return el.node.data.Type == 'Hotel'
  });

  const {
    taglineStyle,
    overviewStyles: { overviewHeading, clientName, metaHeading, listOneStyle },
    social,
    problemSolStyles: { problemHeading, problemIcon },
    resultStyles: { resultText },
  } = restProps;
  
  return (
    <Layout location={location}>
      <Header />
            <SEO title={Title} />
      <main className="site-wrapper-reveal">
        <BannerArea image={bannerImg}>
          <Container>
            <Row justify="center">
              <Col lg={10}>
                <BannerTextWrap>
                  {Title && <Heading {...taglineStyle}>{Title}</Heading>}
                    <Text as="span" className="trip-blurb">{TripBlurb}</Text>
                </BannerTextWrap>
              </Col>
            </Row>
          </Container>
        </BannerArea>
        <OverviewArea pt="40px">
        <TripForm trip={trip} hotels={hotels} />
        <Row mt="40px">
        {final_plan && (
          <Col lg={8} ml="auto" mr="auto">
            <Tabs defaultActiveKey="1">
              <TabHeader className="tab-header">
                   {final_plan && Object.keys(final_plan).map((plan, index) =>    
                       <NavItem className="day-tab" eventKey={index + 1}> Day {index + 1}</NavItem>
                  )}    
              </TabHeader>    
              <TabContent>
                {final_plan && Object.keys(final_plan).map((plan, index) => 
                  <TabPane eventKey={index + 1}>
                    <Timeline items={final_plan[plan]}/>                            
                  </TabPane>
                )}                                                               
              </TabContent>
            </Tabs>
          </Col>
        )}
        </Row>
        </OverviewArea>
      </main>
      <FeaturesArea />
      <CTA />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $title: String!) {
    cityData: allAirtable(
      filter: { table: { eq: "Cities" }, data: { Slug: { eq: $slug } } }
    ) {
      edges {
        node {
          data {
            Name
            Region
            Country
            Note
            FlightTitle
            FlightDescription
            TipsTitle
            TipsDescription
            WorldRegion
            Photo {
              url
            }
            Feature
          }
        }
      }
    }
    trip: allAirtable(
      filter: { table: { eq: "Trips" }, data: { Slug: { eq: $slug } } }
    ) {
      edges {
        node {
          data {
            Title
            Slug
            Period
            TripBlurb
            TripPrice
            TripCTA
            Photo{
              url
            }
            TripCity
            DestinationAirportCode
          }
        }
      }
    }
    plans: allAirtable(
      filter: { table: { eq: "Plan" }, data: { TripName: { eq: $title } } }
    ) {
      edges {
        node {
          data {
            Time
            Cost
            Location
            Address
            InfoProviders
            Description
            Type
            Day
            DayOrder
            TripName
            BookingUrl
            WatchUrl
            Period
            Photo {
              url
            }
            PriceNotIncluded
          }
        }
      }
    }  
  }
`;

TripsTemplate.propTypes = {
  bannerStyle: PropTypes.object,
  taglineStyle: PropTypes.object,
  overviewStyles: PropTypes.object,
  social: PropTypes.object,
  problemSolStyles: PropTypes.object,
  resultStyles: PropTypes.object,
  faqStyles: PropTypes.object,
  navigation: PropTypes.object,
  ListOneStyle: PropTypes.object,
};

TripsTemplate.defaultProps = {
  taglineStyle: {
    as: "h1",
    mt: "20px",
    mb: "15px",
    color: "#fff",
  },
  overviewStyles: {
    listOneStyle: {
      layout: "separator",
    },
    overviewHeading: {
      as: "h5",
      fontSize: "28px",
      lineHeight: 1.43,
      fontweight: 400,
      color: "#002fa6",
    },
    clientName: {
      as: "h6",
      fontweight: "500",
      mb: ".25rem",
    },
    metaHeading: {
      texttransform: "uppercase",
      fontweight: 500,
      fontSize: "14px",
      letterspacing: "1px",
      color: "#333",
    },
  },
  social: {
    color: "#ababab",
    fontSize: "15px",
    tooltip: true,
    tooltip_position: "bottom-left",
  },
  problemSolStyles: {
    problemHeading: {
      as: "h6",
      position: "relative",
      fontSize: "15px",
      fontweight: 500,
      lineHeight: 1.74,
      mb: "20px",
    },
    problemIcon: {
      fontSize: "18px",
      fontweight: 400,
      pr: "15px",
      mt: "5px",
    },
  },
  resultStyles: {
    resultText: {
      fontSize: "24px",
    },
  },
};

export default TripsTemplate;


// <VerticalTimeline>
//                                       {final_plan[plan].map((p, index) => 
//                                         <VerticalTimelineElement
//                                         className="vertical-timeline-element--work"
//                                         contentStyle={{ background: '#DEE3FA', color: '#fff' }}
//                                         contentArrowStyle={{ borderRight: '7px solid  #DEE3FA' }}
//                                         date={formatDate(p.fields.Time)}
//                                         iconStyle={{ background: '#DEE3FA', color: '#fff' }}
//                                         icon={this.getIcon(p.fields.Type)}
//                                         key={index}
//                                       >
//                                         {p.fields.PriceNotIncluded && <CurrencyUsdOffIcon color="#242943" /> }
//                                         <h3 className="vertical-timeline-element-title">{p.fields.Location} </h3>
//                                         <img style={{maxWidth:300}} src={p.fields ? p.fields.Photo[0].url : ''} />
//                                         <p> {p.fields.Description}</p>                                        
//                                         <p style={{width:'100%', float:'left'}}>
//                                         <span style={{float:'left'}}><CurrencyUsdIcon style={{marginTop:5, float: 'left'}} color="#242943" /> <label style={{marginTop:'7px', marginRight:'15px', float:'left', color:'#242943'}}>{p.fields.Cost.toFixed(2)}</label> </span>
//                                           <span style={{float:'left'}}><ClockOutlineIcon style={{marginTop:5, float: 'left'}} color="#242943" /> <label style={{marginTop:'7px', marginLeft:'5px', float:'left', color:'#242943'}}>{p.fields.Duration}</label> </span>
//                                           <span style={{marginTop:'5px', marginLeft:'15px', float:'left'}} className="info-providers"> {p.fields.InfoProviders.map(info =>  <a style={{marginRight:10}} href="#" key={info} target="_blank">{info}</a>)}</span>
//                                         </p>
//                                         <p style={{marginTop:'15px', float:'left', width:'100%'}}><a href={'https://maps.google.com/?q='+p.fields.Address} target="_blank">View Location</a></p>
                                      
//                                       </VerticalTimelineElement>                              
//                                       )}
                          
//                                     </VerticalTimeline>