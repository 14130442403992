import React from "react";

function Hotel({color, width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "300"}
      height={height ? height : "300"}
      x="0"
      y="0"
      viewBox="0 0 100 100"
    >
      <path
        d="M71.719 957.398l-33 10a1.03 1.03 0 00-.72.968v17.343l-31.437 14.75a1.023 1.023 0 00-.562.905v44.998c0 .524.476 1 1 1h86c.523 0 1-.476 1-1v-76.996a1.023 1.023 0 00-.531-.906l-21-11a1.16 1.16 0 00-.75-.062zm1.28 2.625l19 9.937v75.402H73v-85.34zm4 17.343v8h4v-8zm7 0v8h4v-8zm-35 6.218l22 4.594v57.184H49zm28 5.781v8h4v-8zm7 0v8h4v-8zm-32 2v8h4v-8zm6 0v8h4v-8zm6 0v8h4v-8zm-12 10v7.999h4v-8zm6 0v7.999h4v-8zm6 0v7.999h4v-8zm13 0v7.999h4v-8zm7 0v7.999h4v-8zm-32 11.999v8h4v-8zm6 0v8h4v-8zm6 0v8h4v-8zm13 0v8h4v-8zm7 0v8h4v-8zm-32 12v7.999h4v-8zm6 0v7.999h4v-8zm6 0v7.999h4v-8zm13 0v7.999h4v-8zm7 0v7.999h4v-8z"
        style={{
          WebkitTextIndent: "0",
          textIndent: "0",
          WebkitTextTransform: "none",
          textTransform: "none",
          blockProgression: "tb",
        }}
        color={color ? color : "#12B077"}
        fill={color ? color : "#12B077"}
        enableBackground="accumulate"
        overflow="visible"
        transform="translate(0 -952.362)"
      ></path>
    </svg>
  );
}

export default Hotel;
