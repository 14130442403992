import React from "react";

const SuccessIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : '100'}
      height="auto"
      ariaHidden="true"
      className="ft-green-tick"
      viewBox="0 0 48 48"
    >
      <circle cx="24" cy="24" r="22" fill="#5bb543" className="circle"></circle>
      <path
        fill="none"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M14 27l5.917 4.917L34 17"
        className="tick"
      ></path>
    </svg>
  );
}

export default SuccessIcon;
